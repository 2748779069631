<template>
  <div>
    <v-card-text class="bold-f-s p-t-24" :class="isPc ? 'text-center' : 'text-center'"> Hi {{ firstName }} </v-card-text>
    <div :class="isPc ? 'pc-containers' : 'mb-containers'">
      <div :class="isPc ? 'pc-contents welcome-content' : 'mb-contents'">
        <v-card-text :class="isPc ? 'text-center' : 'text-center'">
          <p>
            Welcome <span v-if="identical">back</span> to <span class="bold-f">{{ portfolio.displayName }}.</span>
          </p>
          <p v-if="consentLabel">Prior to finalizing your loan agreement, please carefully review and select the
            consents provided below.</p>
          <p v-if="!consentLabel">Let's start by confirming some basic information.</p>
        </v-card-text>
        <v-card-text :class="isPc ? 'text-center' : 'text-center'">
          <a class="f-s-14" href="javascript:;" @click="disclaimer">Disclaimer</a>
        </v-card-text>
        <v-card-text  style="color: #b3b7c3" :class="isPc ? 'f-s-12 text-center' : 'f-s-12 text-center'">
          <span style="color: #b3b7c3">If you have questions please call us at</span> {{ portfolio.telephone }}
        </v-card-text>
        <div class="consentMblview">
          <v-card-text class="p-t-20" v-if="hasConsent">
            <v-row dense v-for="(item, key) in consentList" :key="key">
              <v-col cols="1" color="var(--v-primary-base)">
                <v-checkbox hide-details v-model="item.check" class="m-0 p-0" @click="nextBtnAble()"></v-checkbox>
              </v-col>
              <v-col cols="11" :key="item.text">
                <div>
                  <span class="m-r-5" style="color: #9e9e9e" v-html="item.labelPrefix"></span>
                  <span @click="onClickProtocol(item)" class="color-secondary">{{ item.templateLabel }}</span>
                  <span
                    v-if="tool.isNotEmpty(item.extension) && tool.isNotEmpty(item.extension.isOptional) && item.extension.isOptional"
                    style="display: none;" color="var(--v-primary-base)"> (optional)</span>
                </div>
              </v-col>
              <!-- Show input field below the second checkbox -->
              <!-- Editable Home Phone Number Field -->
              <v-col cols="1"  v-if="key === 1"></v-col>
              <v-col cols="11" v-if="key === 1">
                <v-text-field v-model="editableHomePhone" v-mask="'(###) ###-####'" label="Phone Number"
                  placeholder="Enter Phone Number" outlined dense hide-details :rules="[(v) => !!v || 'Phone number is required']" :disabled ="disablePhone" ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </div>
        <v-card-text :class="isPc ? 'text-center height-100' : ''" v-if="hasConsent">
          <p v-if="checkConsentList.length > 0">
            By checking the above boxes, and pressing the “CONTINUE” button, you are agreeing to the consents to
            <span v-if="consentList[0].check"> the {{consentList[0].templateLabel}}</span>
            <span v-if="consentList[1].check">
              <span v-if="checkConsentList.length >=1 && consentList[0].check"> and</span>
              {{consentList[1].templateLabel}}</span>
            <span v-if="consentList[2].check">
              <span v-if="checkConsentList.length >1 "> as well</span>
              {{ consentList[2].templateLabel }}</span>
            <span v-if="consentList[3].check">
              <span v-if="checkConsentList.length >2 "> as well</span>
              {{ consentList[3].templateLabel }} self disclosure.</span>
          </p>
        </v-card-text>
      </div>
    </div>
    <v-card-actions :class="isPc ? 'pc-pages-action' : 'm-t-40'">
      <v-btn :loading="startLoading" large color="primary" :disabled="nextBtn" @click="next" width="240"
        class="m-0 f-s-18">
        CONTINUE
      </v-btn>
    </v-card-actions>
    <!-- <v-card-actions v-if="!isPc">
      <a class="f-s-14" href="javascript:;" @click="disclaimer">Disclaimer</a>
    </v-card-actions>
    <div class="mb-pages-action" v-if="!isPc">
      <span style="font-size: 12px; color: #b3b7c3">If you have questions please call us at</span>
      <span style="font-size: 12px; margin-left: 10px">{{ portfolio.telephone }}</span>
    </div> -->
    <v-dialog max-width="480" v-model="showModal">
      <v-card class="overflow-y-auto p-t-20 b-r-16" flat>
        <v-btn @click="showModal = false" icon style="position: absolute; top: 0px; right: 0px">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-text class="text-center"><span class="bold-f f-s-24">Disclaimer</span></v-card-text>
        <v-card-text v-for="(item, index) in portfolio.disclaimer.split('<br/>')" :key="index">
          {{ item }}
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showTemplateContent" max-width="600px" scrollable>
      <v-card class="mx-auto rounded-xl" style="color: #000">
        <v-card-title class="text-h5 grey lighten-2 text-center rounded-t-xl">
          <div class="font-weight-bold" v-html="activeTemplateContent.templateTitle"></div>
          <v-spacer></v-spacer>
          <v-icon @click="showTemplateContent = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text style="max-height: 600px" class="white content-text">
          <div class="m-t-20" v-html="activeTemplateContent.templateContent"></div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { Tool, DataType } from '../../js/core'
import { OriginationApi } from '../../api/application'
const tool = new Tool()
export default {
  data () {
    return {
      page: DataType.PAGES.WELCOME,
      startLoading: false,
      portfolio: {},
      firstName: '',
      tool: tool,
      route: './instant/notification/-200',
      showModal: false,
      identical: false,
      tags: 0,
      hasConsent: false,
      showTemplateContent: false,
      activeTemplateContent: {},
      fromLA: false,
      consentList: [],
      nextBtn: true,
      checkConsentList: [],
      consentLabel: false,
      homePhone: JSON.parse(localStorage.getItem(DataType.COOKIE_KEY.PERSONAL) || '{}')?.homePhone ?? '',
      editableHomePhone: JSON.parse(localStorage.getItem(DataType.COOKIE_KEY.PERSONAL) || '{}')?.homePhone ?? '',
      personalData: JSON.parse(localStorage.getItem(DataType.COOKIE_KEY.PERSONAL) || '{}') ?? {},
      loanId: localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID) ?? '',
      disablePhone: false
    }
  },
  props: {
    overlay: Object,
    isPc: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    'store.getters.getFromLA': {
      handler (val) {
        this.consentLabel = val
        this.fromLA = val
      },
      immediate: true,
      deep: true
    },
    editableHomePhone (value) {
      const $this = this
      if ($this.tool.isEmpty(value)) {
        $this.nextBtn = true
      }
      this.nextBtnAble()
    }
  },
  mounted () {
    this.disablePhone = localStorage.getItem('disablePhone') === 'true'
  },
  methods: {
    onClickProtocol (item) {
      this.activeTemplateContent = {}
      this.showTemplateContent = true
      this.activeTemplateContent = item
    },
    nextBtnAble () {
      const $this = this
      $this.checkConsentList = []
      $this.checkConsentList = $this.consentList.filter(item => item.check)
      if ($this.tool.isEmpty($this.editableHomePhone)) {
        $this.nextBtn = true
        return false
      }
      for (const item of $this.consentList) {
        if ($this.tool.isEmpty(item.extension)) {
          if (!item.check) {
            $this.nextBtn = true
            return false
          }
        }
        if ($this.tool.isNotEmpty(item.extension)) {
          const parsed = JSON.parse(item.extension)
          if (parsed.isInputPhone && !item.check) {
            $this.nextBtn = true
            return false
          }
        }
      }
      $this.nextBtn = false
    },
    disclaimer () {
      this.showModal = true
    },
    samePortfolio () {
      const _this = this
      const portfolioArr = DataType.SAME_PORTFOLIO
      for (const number of portfolioArr) {
        if ((_this.tags & number) === number) {
          return true
        }
      }
      return false
    },
    async next () {
      const _this = this
      _this.startLoading = true
      if (_this.hasConsent) {
        const formattedHomePhone = `(${this.homePhone.slice(0, 3)}) ${this.homePhone.slice(3, 6)}-${this.homePhone.slice(6)}`
        if (formattedHomePhone !== this.editableHomePhone) {
          _this.personalData.homePhone = this.editableHomePhone
          localStorage.setItem(DataType.COOKIE_KEY.PERSONAL, JSON.stringify(_this.personalData))
          await OriginationApi.updateOrigination({
            feature: 'personal',
            data: {
              homePhone: _this.editableHomePhone,
              loanId: _this.loanId
            }
          }, function (result) {
          })
        }
        _this.changeLoanStatusToCustomerReview()
        _this.saveConsentData()
      } else {
        _this.goStep()
      }
    },
    changeLoanStatusToCustomerReview () {
      OriginationApi.updateOrigination({
        feature: 'loan-status',
        data: {
          loanId: this.loanId,
          loanStatus: DataType.LoanStatus.CUSTOMER_REVIEW.value
        }
      })
    },
    saveConsentData () {
      const _this = this
      const arrayList = []
      _this.consentList.map((item) => {
        const saveData = {
          consentTemplateId: item.consentTemplateId,
          status: item.check ? 1 : 0
        }
        if (_this.tool.isNotEmpty(item?.id)) {
          saveData.id = item.id
        }
        arrayList.push(saveData)
      })
      const payload = {
        loanId: localStorage.getItem('LOAN_ID'),
        data: arrayList
      }
      OriginationApi.saveConsentData(
        payload,
        () => {
          if (_this.fromLA) {
            _this.goSignature()
          } else {
            _this.goStep()
          }
        },
        () => {
          _this.startLoading = false
        }
      )
    },
    goSignature () {
      const stepMessage = {
        currect: DataType.PAGES.WELCOME,
        next: DataType.PAGES.SIGNATURE
      }
      this.$eventBus.$emit('sendStepToSloth', stepMessage)
      OriginationApi.enteredAndLeft(
        localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
        DataType.PAGES.WELCOME.step,
        DataType.PAGES.SIGNATURE.step,
        localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)
      )
      if (this.tool.isPC()) {
        this.$router.push(DataType.PAGES.SIGNATURE.addressPC)
      } else {
        this.$router.push(DataType.PAGES.SIGNATURE.address)
      }
    },
    goStep () {
      const _this = this
      const stepMessage = {
        currect: DataType.PAGES.WELCOME,
        next: DataType.PAGES.PERSONAL
      }
      _this.$eventBus.$emit('sendStepToSloth', stepMessage)
      const category = localStorage.getItem(DataType.COOKIE_KEY.CATEGORY)
      if (_this.samePortfolio() && parseInt(category) === DataType.CategoryEnum.RETURNING_CUSTOMER.value) {
        OriginationApi.enteredAndLeft(
          localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
          DataType.PAGES.WELCOME.step,
          DataType.PAGES.BASIC_INFO.step,
          localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)
        )
        _this.$eventBus.$emit('closeCountDown')
        _this.$router.push(DataType.PAGES.BASIC_INFO.addressPC)
      } else {
        OriginationApi.enteredAndLeft(
          localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
          DataType.PAGES.WELCOME.step,
          DataType.PAGES.PERSONAL.step,
          localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)
        )
        _this.$eventBus.$emit('closeCountDown')
        _this.$router.push(DataType.PAGES[_this.page.next].addressPC)
      }
    }
  },
  created () {
    this.$store.commit('setCurrentPage', this.page)
    this.$store.commit('setOverlay', false)
    this.firstName = JSON.parse(localStorage.getItem(DataType.COOKIE_KEY.PERSONAL)).firstName
    this.portfolio = JSON.parse(localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO))
    this.identical = parseInt(JSON.parse(localStorage.getItem('PRIORITY'))) > 900
    const $this = this
    OriginationApi.getLoanById({ loanId: localStorage.getItem('LOAN_ID'), feature: 'loan' }, function (result) {
      $this.tags = result.tags
    })
    history.pushState(null, null, document.URL)
    window.addEventListener('popstate', function () {
      history.pushState(null, null, document.URL)
    })
    OriginationApi.getonsentByLoanId({ loanId: localStorage.getItem('LOAN_ID') }, (res) => {
      if ($this.tool.isNotEmpty(res)) {
        const consentData = JSON.parse(res)
        $this.hasConsent = !!$this.tool.isNotEmpty(consentData?.templates)
        $this.nextBtn = $this.hasConsent
        $this.consentList = $this.tool.isNotEmpty(consentData?.templates) ? consentData.templates : []
        if ($this.tool.isNotEmpty($this.consentList)) {
          $this.consentList.map(item => {
            item.check = false
            if ($this.tool.isNotEmpty(item.extension)) {
              item.extension = JSON.parse(item.extension)
            }
            return {
              // ...item,
              label: $this.replacePlaceholders(item.labelPrefix, $this.portfolio)
            }
          })
        }
      }
    })
  },
  replacePlaceholders (text, portfolio) {
    if (!text || !portfolio) {
      return text
    } else {
      return text
        .replace(/\[TLE\]/g, portfolio.displayName || '[TLE]')
        .replace(/xxx-xxx-xxxx/g, portfolio.telephone || 'xxx-xxx-xxxx')
        .replace(/@uri(?=\.com)/g, `@${portfolio.displayName || 'uri'}`)
    }
  },
  beforeUnmount () {
    localStorage.removeItem('disablePhone')
  }
}
</script>
<style lang="sass" scoped>
.welcome-content
  overflow-y: auto
  overflow-x: hidden
  max-height: calc(100% - 50px)
  width: calc(50%)
.color-secondary
  color: var(--v-primary-base)
  font-size: 16px
  font-family: "DIN Regular", sans-serif !important
  font-weight: 500
  cursor: pointer
.content-text
  & :deep(p)
    color: #000000
.font-weight-bold
  width: calc(100% - 24px)
  & :deep(p)
    font-size: 16px !important
    margin: 0
@media screen and (max-width:650px)
  .consentMblview
    overflow-y:auto
</style>
